<template>
<div class="wrapper congratulation">
        <div class="loginWrapper" style="flex-direction: column;">
            <div class="congratulation-msg">
                <h3>{{ this.name }} <strong>Congratulations!</strong></h3>
                <p><span>Registration completed successfully!</span></p>
                <p>Now that you are registered,<br> <a href="/">sign in</a> to join your team</p>
                <p class="text-center loginContent"><a href="/" class="btn">Sign in</a></p>
                <p class="text-center mt-3 small">Having trouble? <a href="">We can help?</a></p>
            </div>
        </div>
    </div> 
</template>

<script>
import {  mapMutations } from 'vuex'
import axiosInstance from "@/services/AxiosTokenInstance";
import { LOADING_SPINNER_SHOW_MUTATION } from '@/store/storeconstants';
export default {
  title () {
    return `Remote Assistance | ${this.title}`
  },  
    data(){
        return{
            title: 'Congratulation',
            name:'',
           userId:'',
            errors: [],
            serverError: '',
        }
    },
    methods: {
        // ...mapActions('auth',{
        //     client_register: CLIENT_REGISTER,
        // }),
        ...mapMutations({
            showLoading: LOADING_SPINNER_SHOW_MUTATION,
        }),
        switchVisibility(){
            this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
            this.faClass = this.faClass === 'fa fa-eye-slash' ? 'fa fa-eye' : 'fa fa-eye-slash';
        },
        
        async getData(value){
            this.userId = this.$route.params.userid;
          this.showLoading(true);
          this.currentPage = value;       
         
              let response = await axiosInstance.get(`getclientdetail/${this.userId}`);
              let responseData = response.data.data;
              this.entries = responseData;
              this.name = responseData.name;
              this.perPage = responseData;
              this.total = responseData.no_of_records;
              this.showLoading(false);              
          
      },
        
    },
 mounted(){
      this.currentPage = 1;
      this.getData(this.currentPage);
  },       
    
}
</script>